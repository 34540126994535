<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading" style="opacity: 0.5;"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 wow fadeInDown">
                            <h5>Import Data Excel</h5>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Import Data Excel</h5>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="col-sm-12">
                                        <div class="col-sm-12 text-center">
                                            <a :href="folder_excel + 'excel_di_contoh.xlsx'" class="hitam">
                                                <button type="button" class="btn btn-success btn-sm text-white lebar2"
                                                    data-toggle="tooltip" data-placement="left"
                                                    title="Unduh master excel..">
                                                    <i class="fas fa-download"></i> Unduh Master .xlsx
                                                </button>
                                            </a>
                                        </div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12">
                                            <label>Pilih File<small class="text-info"> (*.xlsx)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_excel" name="fileToUploadfilenya_excel"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    @change="onChange">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_excel" name="filenya_excel" placeholder="File Excel">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-8">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_excel">
                                                            <span id="tulisanpersenfilenya_excel">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_excel"></span>
                                                </div>
                                                <div class="col-sm-2 text-center" v-if="tempjumlah > 0">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadsavedata()">
                                                        Simpan ke DB
                                                    </button>
                                                </div>
                                                <div class="col-sm-2 text-center" v-if="tempjumlah > 0">
                                                    <button type="button"
                                                        class="btn btn-success btn-sm lebar text-white"
                                                        @click="cekdataDB()">
                                                        Cek Data DB
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <div class="col-sm-12">
                                            Jumlah Data P3A excel siap Import: {{ tempjumlah }}
                                            <span style="float: right;">Jumlah Data P3A excel Tersimpan di DB: {{
                                                tempjumlahdb
                                            }}</span>
                                            <div class="table-responsive table-wrapper" v-if="tempjumlah > 0">
                                                <table class="table m-0" id="tabeldata2">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Urut
                                                            </th>
                                                            <template v-for="(dataheader, urutheader) in tempheader"
                                                                :key="urutheader">
                                                                <th>
                                                                    {{ dataheader }}
                                                                </th>
                                                            </template>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datadata, urutdata) in tempdata" :key="urutdata">
                                                        <tr v-if="urutdata > 0">
                                                            <td>{{ urutdata }}</td>
                                                            <td v-for="(isidata, urutisi) in datadata" :key="urutisi">
                                                                {{ isidata }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="col-sm-12">
                                        <label>*Pilih data exel (silahkan unduh jika belum ada master data template
                                            excel)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <p>&nbsp;<br></p>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import readXlsxFile from 'read-excel-file';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            tempheader: [],
            tempdata: [],
            tempjumlah: 0,
            tempjumlahdb: 0,
            datapesan: '',
            folder_excel: process.env.VUE_APP_URL_API + 'parastapainnovation_excel-DownloadExcel?f=',
        }
    },
    methods: {
        onChange(event) {
            this.tempjumlahdb = 0;
            try {
                if (event.target.files[0]['type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    this.file = event.target.files ? event.target.files[0] : null;
                    if (this.file) {
                        this.tempheader = [];
                        this.tempdata = [];
                        readXlsxFile(this.file).then((rows) => {
                            // `rows` is an array of rows
                            // each row being an array of cells.
                            //alert(rows.length);
                            this.halamanloading = true;
                            this.tempjumlah = rows.length - 2;
                            for (let i = 0; i < rows.length; i++) {
                                this.halamanloading = true;
                                if (i == 0) {
                                    var varheader = rows[i];
                                    for (let x = 0; x < varheader.length; x++) {
                                        this.tempheader.push(varheader[x]);
                                    }
                                }
                                else {
                                    this.tempdata.push(rows[i]);
                                }
                                document.getElementById('persennyafilenya_excel').style.width = Math.round((i / (rows.length - 1)) * 100) + "%";
                                document.getElementById('tulisanpersenfilenya_excel').innerHTML = Math.round((i / (rows.length - 1)) * 100) + "%";
                            }
                            //console.log(this.tempdata);
                            this.halamanloading = false;
                        })
                    }
                }
                else {
                    swal.fire('Peringatan', 'File tidak sesuai format', 'error');
                    return false;
                }
            } catch (error) {

            }

        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        async cekdataDB() {
            for (let i = 0; i < this.tempdata.length; i++) {
                if (i == 1) {
                    for (let x = 0; x < this.tempdata[i].length; x++) {
                        if (x == 3) {
                            var kodeirigasi = this.tempdata[i][x];
                        }
                    }
                }
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_portal-GetDI?random=" + random + "&kodeDI=" + kodeirigasi).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.tempjumlahdb = Response.data.resume[0].JumlahTotalP3A;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.tempjumlahdb = 0;
                this.halamanloading = false;
            });
        },
        async uploadsavedata() {
            this.tempjumlahdb = 0;
            var tempheader = this.tempheader;
            //alert(this.tempdata.length);
            var temparraydata = ''
            //return false;
            for (let i = 0; i < this.tempdata.length; i++) {
                if (i == 0) {
                    var acuan = this.tempdata[i];
                }
                else {
                    //console.log(JSON.stringify(tempheader));
                    //console.log(JSON.stringify(acuan));
                    var data = "";
                    for (let x = 0; x < this.tempdata[i].length; x++) {
                        if (x == 0) {
                            data = this.tempdata[i][x];
                        }
                        else {
                            data = data + '|' + this.tempdata[i][x];
                        }
                    }

                    if (i == 1) {
                        temparraydata = temparraydata + data;
                    }
                    if (i > 1) {
                        temparraydata = temparraydata + '|+|' + data;
                    }
                    //alert(JSON.stringify(tempheader));
                    //alert(JSON.stringify(acuan));
                    //return false;


                }
            }
            var fd = new FormData();
            fd.append("tempheader", tempheader);
            fd.append("acuan", acuan);
            fd.append("data", temparraydata);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            this.halamanloading = true;
            await mainAPI.post("parastapainnovation_excel-SaveExcel", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        this.halamanloading = false;
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        this.halamanloading = false;
                    }
                }
            ).catch(function (error) {
                this.halamanloading = false;
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            //alert(tempheader);
            //alert(acuan);
            //alert(data);
        },
    },
    mounted() {
        this.getOtentifikasi();
    }
}
</script>
<style></style>